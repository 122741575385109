export type Category = string;

export interface ShoppingListItem {
  id: number;
  amount: number;
  text: string;
  category: Category;
  completed: boolean;
}

export interface ShoppingList {
  id: number;
  name: string;
  items: ShoppingListItem[];
}

interface ActionType<T, P> {
  type: T;
  payload: P;
}

export enum ShoppingListActions {
  ADD_ITEM = "ADD_ITEM",
  MOVE_ITEM = "MOVE_ITEM",
  DELETE_ITEM = "DELETE_ITEM",
  COMPLETE_ITEM = "COMPLETE_ITEM",
  UNCOMPLETE_ITEM = "UNCOMPLETE_ITEM",
  
  ADD_LIST = "ADD_LIST",
  RENAME_LIST = "RENAME_LIST",
  DELETE_LIST = "DELETE_LIST",
}

export interface ShoppingListActionDataType<P> {
  listId: number;
  payload: P;
}

export interface MoveItemData {
  from: number;
  to: number;
}

export type ShoppingListAction =
  | ActionType<typeof ShoppingListActions.ADD_ITEM, ShoppingListActionDataType<ShoppingListItem>>
  | ActionType<typeof ShoppingListActions.MOVE_ITEM, ShoppingListActionDataType<MoveItemData>>
  | ActionType<typeof ShoppingListActions.DELETE_ITEM, ShoppingListActionDataType<number>>
  | ActionType<typeof ShoppingListActions.COMPLETE_ITEM, ShoppingListActionDataType<number>>
  | ActionType<typeof ShoppingListActions.UNCOMPLETE_ITEM, ShoppingListActionDataType<number>>
  | ActionType<typeof ShoppingListActions.ADD_LIST, string>
  | ActionType<typeof ShoppingListActions.DELETE_LIST, number>
  | ActionType<typeof ShoppingListActions.RENAME_LIST, ShoppingListActionDataType<string>>
;