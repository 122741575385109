// prettier-ignore
import { Checkbox, Chip, IconButton, Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@material-ui/core";
import {
  SortableContainer,
  SortableElement,
  SortableHandle
} from "react-sortable-hoc";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import DragHandleIcon from "@material-ui/icons/DragHandle";
import AddIcon from '@material-ui/icons/Add';
import ClearIcon from '@material-ui/icons/Clear';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { makeStyles } from "@material-ui/styles";
import * as React from "react";
import { useActions } from "../actions";
import * as ShoppingListActions from "../actions/shoppinglist";
import { ShoppingListItem } from "../model";
import { ShoppingList } from "../model"
import { AddProductDialog } from ".";
import { ChangeShoppingListNameDialog } from ".";
import { callEventHandler } from "./CallEventHandler";
import { history } from "../configureStore";
import { HamsterWarningDialog } from "./HamsterWarningDialog";

const DragHandle = SortableHandle(() => (
  <ListItemIcon>
    <DragHandleIcon />
  </ListItemIcon>
));

interface SortableItemProps {
	item: ShoppingListItem;
	onItemClick?: (item: ShoppingListItem) => void;
	onRemoveItemClick?: (item: ShoppingListItem) => void;
}

const SortableItem = SortableElement(({ item, onItemClick, onRemoveItemClick }: SortableItemProps) => {
	const classes = useStyles();
	const labelId = `checkbox-label-${item.id}`;

	return (
		<ListItem
			button
			onClick={() => callEventHandler(onItemClick, item)}
			ContainerComponent="div"
		>
			<ListItemIcon>
				<div className={classes.clearButton} onClick={() => callEventHandler(onRemoveItemClick, item)}>
					<ListItemIcon>
						<ClearIcon />
					</ListItemIcon>
				</div>
			</ListItemIcon>
			<ListItemText id={labelId}>
				{`${item.amount} ${item.text}`}
				{item.category != null ? (<Chip label={item.category} variant="outlined" className={classes.listItemChip} />) : undefined}
			</ListItemText>
			<ListItemSecondaryAction className={classes.rowTools}>
				<div className={classes.dragHandle}>
					<DragHandle />
				</div>
			</ListItemSecondaryAction>
		</ListItem>
	)
});

interface SortableListContainerProps {
	items: ShoppingListItem[];
	onItemClick?: (item: ShoppingListItem) => void;
	onRemoveItemClick?: (item: ShoppingListItem) => void;
}

const SortableListContainer = SortableContainer(({ items, onItemClick, onRemoveItemClick }: SortableListContainerProps) => (
  <List component="div">
    {items.map((item: ShoppingListItem, index: number) => (
      <SortableItem key={item.id} index={index} item={item} onItemClick={onItemClick} onRemoveItemClick={onRemoveItemClick} />
    ))}
  </List>
));

const SortableList = (list: ShoppingList) => {
  const shoppingListActions = useActions(ShoppingListActions);

  const onSortEnd = ({ oldIndex, newIndex }: { oldIndex: number, newIndex: number }) => {
	shoppingListActions.moveItem(list.id, oldIndex, newIndex);
  };
  
  const onItemClick = (item: ShoppingListItem) => {
	if (item.completed)
		shoppingListActions.uncompleteItem(list.id, item.id);
	else
		shoppingListActions.completeItem(list.id, item.id);
  };

  const onRemoveItemClick = (item: ShoppingListItem) => {
	shoppingListActions.deleteItem(list.id, item.id);
  };

  return (
    <SortableListContainer
      items={list.items}
	  onSortEnd={onSortEnd}
	  onItemClick={onItemClick}
	  onRemoveItemClick={onRemoveItemClick}
      useDragHandle={true}
	  lockAxis="y"
    />
  );
};

function OpenCloseDialogHelper(initialState: boolean) {
	const [open, setOpen] = React.useState(initialState);
	return {
		open: open,
		setOpen: setOpen,
		handleClose: () => setOpen(false),
		handleOpen: () => setOpen(true)
	}
}

interface ItemListProps {
	list: ShoppingList;
}

export function ItemList({list}: ItemListProps) {
	const classes = useStyles();

	const addProductDialogHelper = OpenCloseDialogHelper(false);
	const changeShoppingListNameDialogHelper = OpenCloseDialogHelper(false);
	const hamsterDialogHelper = OpenCloseDialogHelper(false);
	const shoppingListActions = useActions(ShoppingListActions);

	return (
		<Paper className={classes.paper}>
			<AddProductDialog openHamsterWarningDialog={hamsterDialogHelper.handleOpen} listId={list.id} open={addProductDialogHelper.open} onClose={addProductDialogHelper.handleClose} />
			<HamsterWarningDialog open={hamsterDialogHelper.open} onClose={hamsterDialogHelper.handleClose} />
			<ChangeShoppingListNameDialog list={list} open={changeShoppingListNameDialogHelper.open} onClose={changeShoppingListNameDialogHelper.handleClose} />
			<div className={classes.listHeader}>
				<div className={classes.nameAndListEdit}>
					<Typography className={classes.listName} variant="h6" align="left">
						{list.name}
					</Typography>
					<IconButton onClick={changeShoppingListNameDialogHelper.handleOpen}>
						<EditIcon />
					</IconButton>
					<IconButton onClick={() => { shoppingListActions.deleteList(list.id); history.push("/"); }}>
						<DeleteIcon />
					</IconButton>
				</div>
				<IconButton onClick={addProductDialogHelper.handleOpen}>
					<AddIcon />
				</IconButton>
			</div>

			<div className={classes.list}>
				<SortableList {...list} />
			</div>
		</Paper>
	);
}

const useStyles = makeStyles({
	paper: {
		width: "100%",
		minWidth: 260,
		display: "inline-block",
		marginBottom: 20,
		
	},
	list: {
		width: "100%",
	},
	listHeaderIcon: {
		marginRight: 16,
	},
	listHeader: {
		justifyContent: "space-between",
		display: "flex",
		alignItems: "center",
		paddingLeft: 20,
		paddingTop:15,
		paddingRight: 16,
	},
	nameAndListEdit: {
		display: "flex",
		alignItems: "center",
	},
	listName: {
		paddingRight: "0.5em",
	},
	listItemChip: {
		marginLeft: 10,
	},
	listItemSecondaryActionChip: {
		marginRight: 5,
	},
	clearButton: {
		maxWidth: 30,
		paddingLeft: 5,
	},
	dragHandle: {
		maxWidth: 30,
		paddingLeft: 5,
	},
	rowTools: {
		display: "flex",
		justifyContent: "flex-end",
	},
});
