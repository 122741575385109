import { ShoppingListItem, ShoppingListActions, ShoppingListAction, ShoppingList, ShoppingListActionDataType, MoveItemData } from "../model";
import createReducer from "./createReducer";
import arrayMove from "array-move";

function addItem(list: ShoppingList, item: ShoppingListItem) : ShoppingList {
	const id = Math.max(0, ...list.items.map(item => item.id)) + 1;
	return { ...list, items: [...list.items, {...item, id}] };
}

function moveItem(list: ShoppingList, from: number, to: number) : ShoppingList {
	return { ...list, items: arrayMove(list.items, from, to) };
}

function removeItem(list: ShoppingList, id: number) : ShoppingList {
	return { ...list, items: list.items.filter(item => item.id !== id) };
}

function setCompletionStatus(list: ShoppingList, itemId: number, value: boolean) : ShoppingList {
	return { ...list, items: list.items.map(item =>  item.id === itemId ? { ...item, completed: value } : item ) };
}

function applyToList(state: ShoppingList[], listId: number, f: (list: ShoppingList) => ShoppingList) : ShoppingList[] {
	return state.map(list => list.id === listId ? f(list) : list);
}

export const shoppingLists = createReducer<ShoppingList[]>([], {
	[ShoppingListActions.ADD_ITEM](state: ShoppingList[], action: ShoppingListAction) {
		const info = action.payload as ShoppingListActionDataType<ShoppingListItem>;
		const item = info.payload;
		return applyToList(state, info.listId, list => addItem(list, item));
	},
	[ShoppingListActions.MOVE_ITEM](state: ShoppingList[], action: ShoppingListAction) {
		const info = action.payload as ShoppingListActionDataType<MoveItemData>;
		const fromTo = info.payload;
		return applyToList(state, info.listId, list => moveItem(list, fromTo.from, fromTo.to));
	},
	[ShoppingListActions.COMPLETE_ITEM](state: ShoppingList[], action: ShoppingListAction) {
		const info = action.payload as ShoppingListActionDataType<number>;
		const itemId = info.payload;
		return applyToList(state, info.listId, list => setCompletionStatus(list, itemId, true));
	},
	[ShoppingListActions.UNCOMPLETE_ITEM](state: ShoppingList[], action: ShoppingListAction) {
		const info = action.payload as ShoppingListActionDataType<number>;
		const itemId = info.payload;
		return applyToList(state, info.listId, list => setCompletionStatus(list, itemId, false));
	},
	[ShoppingListActions.DELETE_ITEM](state: ShoppingList[], action: ShoppingListAction) {
		const info = action.payload as ShoppingListActionDataType<number>;
		const itemId = info.payload;
		return applyToList(state, info.listId, list => removeItem(list, itemId));
	},
	[ShoppingListActions.ADD_LIST](state: ShoppingList[], action: ShoppingListAction) {
		const max = Math.max(0, ...state.map(s => s.id));
		return [...state, { id: max + 1, name: action.payload , items: []}];
	},
	[ShoppingListActions.DELETE_LIST](state: ShoppingList[], action: ShoppingListAction) {
		return state.filter(list => list.id !== action.payload);
	},
	[ShoppingListActions.RENAME_LIST](state: ShoppingList[], action: ShoppingListAction) {
		const info = action.payload as ShoppingListActionDataType<string>;
		return applyToList(state, info.listId, list => ({...list, name: info.payload}));
	},
});