import { createBrowserHistory } from "history";
import * as localforage from "localforage";
import { applyMiddleware, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { createLogger } from "redux-logger";
import { PersistConfig, persistReducer, persistStore } from "redux-persist";
import thunk from "redux-thunk";
import rootReducer, { RootState } from "./reducers";

const persistConfig: PersistConfig<any> = {
	key: "root",
	version: 1,
	storage: localforage,
	blacklist: [],
};

const logger = (createLogger as any)();
const history = createBrowserHistory();

const dev = process.env.NODE_ENV === "development";

let middleware = dev ? applyMiddleware(thunk, logger) : applyMiddleware(thunk);

if (dev) {
	middleware = composeWithDevTools(middleware);
}

const defaultStore: RootState = {
	shoppingLists: [
		{
			id: 1,
			name: "Hamsterkauf am 23. 3.",
			items: [
				{ id: 1, amount: 1, text: "Apfel", category: "Obst", completed: false },
				{ id: 2, amount: 1, text: "Karotte", category: "Gemüse", completed: false },
				{ id: 3, amount: 1, text: "Milch", category: "Milchprodukte", completed: false },
				{ id: 4, amount: 1, text: "Zauberstab", category: "Magie", completed: false },
			],
		},
		{
			id: 2,
			name: "Einkaufstour am 29. 3.",
			items: [
				{ id: 1, amount: 3, text: "Apfel", category: "Obst", completed: false },
				{ id: 2, amount: 1, text: "Karotte", category: "Gemüse", completed: false },
				{ id: 3, amount: 1, text: "Milch", category: "Milchprodukte", completed: false },
				{ id: 4, amount: 1, text: "Zauberstab", category: "Magie", completed: false },
				{ id: 5, amount: 2, text: "Klopapier", category: "Magie", completed: false },
				{ id: 6, amount: 1, text: "Basic Toolbox", category: "Exzellentes Lernmaterial", completed: true },
			],
		},
	],
};

const persistedReducer = persistReducer(persistConfig, rootReducer(history));

export default () => {
	const store = createStore(persistedReducer, defaultStore, middleware) as any;
	const persistor = persistStore(store);
	return { store, persistor };
};

export { history };
