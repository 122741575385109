// prettier-ignore
import { Dialog, DialogTitle, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import * as React from "react";
import {toNumber} from "lodash";
import * as ShoppingListActions from "../actions/shoppinglist";
import { useActions } from "../actions";
import { isUndefOrEmpty } from "./StringHelper";
import { OkCancelButtons } from "./OkCancelButtons"
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

interface AddProductDialogProperties {
	open: boolean;
	listId: number;
	openHamsterWarningDialog: () => void;
	onClose: () => void;
}

export function AddProductDialog(props: AddProductDialogProperties) {
	const { open, listId, onClose, openHamsterWarningDialog } = props;
	const classes = useStyles();
	const [newProductName, setNewProductName] = React.useState("");
	const [newAmount, setNewAmount] = React.useState(1);
	const [newCategory, setCategory] = React.useState("");
	const shoppingListActions = useActions(ShoppingListActions);
	const isValid = (): boolean => !(isUndefOrEmpty(newProductName) || isUndefOrEmpty(newCategory));
	const highAmountWarning = (): boolean => (toNumber(newAmount)>7);

	const handleClose = () => {
		if(highAmountWarning()) { 
			openHamsterWarningDialog();
		}
		else{
			onClose();
			setNewProductName("");
			setCategory("");
			setNewAmount(1);
		}
	};

	const handleNameChange = (event: any) => {
		setNewProductName(event.target.value);
	};

	const handleCategoryChange = (event: any) => {
		setCategory(event.target.value);
	};

	const handleAmountChange = (event: any) => {
		setNewAmount(Math.max(toNumber(event.target.value), 1));
	};

	return (
		<Dialog open={open} onClose={handleClose}>
			<DialogTitle>Produkt hinzufügen</DialogTitle>
			<TextField
				value={newAmount}
				onChange={handleAmountChange}
				label="Menge"
				type="number"
				className={classes.textField}
			/>
			<TextField
				value={newProductName}
				onChange={handleNameChange}
				className={classes.textField}
				label="Produkt"
			/>
			<Select
				value={newCategory}
				onChange={handleCategoryChange}
				className={classes.textField}
				placeholder="Kategorie"
			>
				<MenuItem value={"Obst"}>Obst</MenuItem>
				<MenuItem value={"Gemüse"}>Gemüse</MenuItem>
				<MenuItem value={"Milchprodukte"}>Milchprodukte</MenuItem>
				<MenuItem value={"Fleisch, Fisch"}>Fleisch und Fisch</MenuItem>
				<MenuItem value={"Fertigprodukte"}>Fertigprodukte</MenuItem>
				<MenuItem value={"Nudeln, Reis"}>Nudeln und Reis</MenuItem>
				<MenuItem value={"Konserven"}>Konserven</MenuItem>
				<MenuItem value={"Brot, Gebäck"}>Brot und Gebäck</MenuItem>
				<MenuItem value={"Snacks, Süßes"}>Snacks und Süßes</MenuItem>
				<MenuItem value={"Getränke"}>Getränke</MenuItem>
				<MenuItem value={"Sonstiges"}>Sonstiges</MenuItem>
			</Select>
			<OkCancelButtons
				onOk={() => {
					shoppingListActions.addItem(listId, {
						id: 0,
						amount: newAmount,
						text: newProductName,
						category: newCategory,
						completed: false,
					});
				}}
				enableOk={isValid()}
				onClose={handleClose}
			/>
		</Dialog>
		
	);
}

function OpenCloseDialogHelper(initialState: boolean) {
	const [open, setOpen] = React.useState(initialState);
	return {
		open: open,
		setOpen: setOpen,
		handleClose: () => setOpen(false),
		handleOpen: () => setOpen(true)
	}
}

const useStyles = makeStyles({
	textField: {
		width: "80%",
		margin: 20,
	},
});
