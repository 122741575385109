import { ShoppingListItem, ShoppingListAction, ShoppingListActions } from "../model";

export function addItem(listId: number, item: ShoppingListItem): ShoppingListAction {
	return {
		type: ShoppingListActions.ADD_ITEM,
		payload: { listId, payload: item },
	};
}

export function moveItem(listId: number, from: number, to: number): ShoppingListAction {
	return {
		type: ShoppingListActions.MOVE_ITEM,
		payload: { listId, payload: {from, to} },
	};
}

export function completeItem(listId: number, itemId: number) {
	return {
		type: ShoppingListActions.COMPLETE_ITEM,
		payload: { listId, payload: itemId },
	};
}

export function uncompleteItem(listId: number, itemId: number): ShoppingListAction {
	return {
		type: ShoppingListActions.UNCOMPLETE_ITEM,
		payload: { listId, payload: itemId },
	};
}

export function deleteItem(listId: number, itemId: number): ShoppingListAction {
	return {
		type: ShoppingListActions.DELETE_ITEM,
		payload: { listId, payload: itemId },
	};
}

export function addList(name: string): ShoppingListAction {
	return {
		type: ShoppingListActions.ADD_LIST,
		payload: name,
	};
}

export function deleteList(listId: number): ShoppingListAction {
	return {
		type: ShoppingListActions.DELETE_LIST,
		payload: listId,
	};
}

export function renameList(listId: number, text: string): ShoppingListAction {
	return {
		type: ShoppingListActions.RENAME_LIST,
		payload: { listId, payload: text },
	}
}