import { Grid, Typography } from "@material-ui/core";
import { Theme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";
import * as React from "react";
import { ItemList } from "../components";
import {toNumber} from 'lodash';
import { ShoppingList } from "../model";
import { useSelector } from "react-redux";
import { RootState } from "../reducers";

export function ItemPage(props: any) {
	const classes = useStyles();
	const listId = toNumber(props.match.params.id);
	const shoppingLists = useSelector((state: RootState) => state.shoppingLists);
	const list: ShoppingList = shoppingLists.find((list) => list.id === listId) as ShoppingList;


	return (
		<Grid container className={classes.root}>			
			<Grid item xs={12}>
				<ItemList list={list} />
			</Grid>
		</Grid>
	);
}

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		padding: 20,
		[theme.breakpoints.down("md")]: {
			padding: 15,
		},
	},
	personButton: {
		position: "fixed",
		bottom: 100,
		right: 35,
	},
	heading: {
		paddingBottom: 15,
	}
}));
