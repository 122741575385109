// prettier-ignore
import { Checkbox, Chip, IconButton, Paper, Typography } from "@material-ui/core";
import {
  SortableContainer,
  SortableElement,
  SortableHandle
} from "react-sortable-hoc";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import DragHandleIcon from "@material-ui/icons/DragHandle";
import AddIcon from '@material-ui/icons/Add';
import SortIcon from '@material-ui/icons/Sort';
import { makeStyles } from "@material-ui/styles";
import * as React from "react";
import { useSelector } from "react-redux";
import { useActions } from "../actions";
import * as ShoppingListActions from "../actions/shoppinglist";
import { ShoppingListItem } from "../model";
import { RootState } from "../reducers";
import { ShoppingList } from "../model"
import { AddProductDialog } from ".";

const DragHandle = SortableHandle(() => (
  <ListItemIcon>
    <DragHandleIcon />
  </ListItemIcon>
));

interface SortableItemProps {
	item: ShoppingListItem;
	onClick?: (item: ShoppingListItem) => void;
}

const SortableItem = SortableElement(({ item, onClick }: SortableItemProps) => {
	const classes = useStyles();
	const labelId = `checkbox-label-${item.id}`;

	return (
		<ListItem
			button
			onClick={() => {if(onClick !== undefined) onClick(item)}}
			ContainerComponent="div"
		>
			<ListItemIcon>
				<Checkbox
					edge="start"
					checked={item.completed}
					tabIndex={-1}
					disableRipple
					onClick={() => {if(onClick !== undefined) onClick(item)}}
					inputProps={{ 'aria-labelledby': labelId }}
				/>
			</ListItemIcon>
			<ListItemText id={labelId}>
				{`${item.amount} ${item.text}`}
				{item.category !== null ? (<Chip label={item.category} variant="outlined" className={classes.listItemChip} />) : undefined}
			</ListItemText>
			<ListItemSecondaryAction>
				<div className={classes.dragHandle}>
					<DragHandle />
				</div>
			</ListItemSecondaryAction>
		</ListItem>
	)
});

interface SortableListContainerProps {
	items: ShoppingListItem[];
	onItemClick?: (item: ShoppingListItem) => void;
}

const SortableListContainer = SortableContainer(({ items, onItemClick }: SortableListContainerProps) => (
  <List component="div">
    {items.map((item: ShoppingListItem, index: number) => (
      <SortableItem key={item.id} index={index} item={item} onClick={onItemClick} />
    ))}
  </List>
));

const SortableList = (list: ShoppingList) => {
  const shoppingListActions = useActions(ShoppingListActions);

  const onSortEnd = ({ oldIndex, newIndex }: { oldIndex: number, newIndex: number }) => {
	shoppingListActions.moveItem(list.id, oldIndex, newIndex);
  };
  
  const onItemClick = (item: ShoppingListItem) => {
	if (item.completed)
		shoppingListActions.uncompleteItem(list.id, item.id);
	else
		shoppingListActions.completeItem(list.id, item.id);
  };

  return (
    <SortableListContainer
      items={list.items}
	  onSortEnd={onSortEnd}
	  onItemClick={onItemClick}
      useDragHandle={true}
	  lockAxis="y"
    />
  );
};

function OpenCloseDialogHelper(initialState: boolean) {
	const [open, setOpen] = React.useState(initialState);
	return {
		open: open,
		setOpen: setOpen,
		handleClose: () => setOpen(false),
		handleOpen: () => setOpen(true)
	}
}

interface ShopListProps {
	listId: number;
}

export function ShopList({listId}: ShopListProps) {
	const classes = useStyles();
	const shoppingLists = useSelector((state: RootState) => state.shoppingLists);

	const list: ShoppingList = shoppingLists.find((list) => list.id == listId) as ShoppingList;

	return (
		<Paper className={classes.paper}>
			<div className={classes.listHeader}>
				<div className = {classes.listHeaderText}>
					<Typography variant="h6" align="left">{list.name}</Typography>
				</div>
				<div className = {classes.listHeaderIcon}>
					<IconButton>
						<SortIcon />
					</IconButton>
				</div>
			</div>

			<div className={classes.list}>
				<SortableList {...list} />
			</div>
		</Paper>
	);
}

const useStyles = makeStyles({
	paper: {
		width: "100%",
		minWidth: 260,
		display: "inline-block",
		marginBottom: 20,

	},
	list: {
		width: "100%",
	},
	listHeaderIcon: {
		marginRight: 16,
	},
	listHeaderText: {
		paddingTop: 8,
	},
	listHeader: {
		justifyContent: "space-between",
		alignSelf: "center",
		display: "flex",
		paddingLeft: 20,
		paddingTop:15,
	},
	listItemChip: {
		marginLeft: 10,
	},
	listItemSecondaryActionChip: {
		marginRight: 5,
	},
	dragHandle: {
		maxWidth: 30,
		paddingLeft: 5,
	}
});
