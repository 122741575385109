import { makeStyles } from "@material-ui/styles";
import * as React from "react";

export function AboutPage() {
	const classes = useStyles();

	return (
		<div className={classes.root}>
			<div className={classes.centerContainer}>
				<img src="/logo.png" className={classes.logo}/>
				<div className={classes.aboutText} >
					<h3>Hamsta</h3>
					von Carla Sagebiel, Julian Wiesler, Katrin Schröder-Bergen, Konstantin Fickel und Philipp Dahlinger.
				</div>
			</div>
		</div>
	);
}

const useStyles = makeStyles({
	root: {
		height: "100%",
		textAlign: "center",
		paddingTop: 20,
		paddingLeft: 15,
		paddingRight: 15,
	},
	logo: {
		width: '80 %',
		maxWidth: 300,
	},
	aboutText: {
		marginTop: 20
	},
	centerContainer: {
		flex: 1,
		height: "90%",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		flexDirection: "column",
	},

	button: {
		marginTop: 20,
	},
});
