import { History } from "history";
import { combineReducers } from "redux";
import * as shoppingListReducer from "./shoppinglist";
import { ShoppingList } from "../model";

export interface RootState {
	shoppingLists: ShoppingList[];
}

export default (history: History) =>
	combineReducers({
		...shoppingListReducer,
	});
