// prettier-ignore
import { Button, Dialog, DialogTitle, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import * as React from "react";
import * as ShoppingListActions from "../actions/shoppinglist";
import { useActions } from "../actions";
import { isUndefOrEmpty } from "./StringHelper";
import { OkCancelButtons } from "./OkCancelButtons"
import { Typography } from "@material-ui/core";

interface Props {
	open: boolean;
	onClose: () => void;
}

export function HamsterWarningDialog(props: Props) {
	const { open, onClose } = props;


	const handleClose = () => {
		onClose();
	};


	return (
		<Dialog open={open} onClose={handleClose}>
			<Typography variant="h3" align="center">Ertappt!</Typography>
			<Typography variant="body1" align="center">Bitte nicht hamstern</Typography>
			<img src="/shockedHamsta.png"/>
			<Button color="primary" onClick={handleClose}>
				OK
			</Button>			
		</Dialog>
	);
}			

const useStyles = makeStyles({
	textField: {
		width: "80%",
		margin: 20,
	},
});
