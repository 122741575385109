// prettier-ignore
import * as React from "react";
import { Button, DialogActions } from "@material-ui/core";
import { mapOptional } from "./CallEventHandler";

export interface OkCancelButtonsProps {
	onCancel?: () => void;
	onOk?: () => void;
	onClose?: () => void;
	enableOk: boolean;
}

export const OkCancelButtons = ({onCancel, onOk, onClose, enableOk}: OkCancelButtonsProps) => {
	const onCancelClick = () => {
		mapOptional(onCancel, v => v());
		mapOptional(onClose, v => v());
	}

	const onOkClick = () => {
		mapOptional(onOk, v => v());
		mapOptional(onClose, v => v());
	}

	return (
		<DialogActions>
			<Button autoFocus onClick={onCancelClick} color="primary">
				Cancel
			</Button>
			<Button color="secondary" disabled={!enableOk} onClick={onOkClick}>
				OK
			</Button>
		</DialogActions>
	)
}