import { Grid, Typography } from "@material-ui/core";
import { Theme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";
import * as React from "react";
import { ShopList } from "../components";
import {toNumber} from "lodash";

export function ShopPage(props: any) {
	const classes = useStyles();

	const listId = toNumber(props.match.params.id);

	return (
		<Grid container className={classes.root}>
			<Grid item xs={12}>
				<Typography variant="h4" gutterBottom>
					ACTION!
				</Typography>
			</Grid>
			
			<Grid item xs={12}>
				<ShopList listId={listId}/>
			</Grid>
		</Grid>
	);
}

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		padding: 20,
		[theme.breakpoints.down("md")]: {
			padding: 15,
		},
	},
}));
