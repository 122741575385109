// prettier-ignore
import { Dialog, DialogTitle, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import * as React from "react";
import * as ShoppingListActions from "../actions/shoppinglist";
import { useActions } from "../actions";
import { ShoppingList } from "../model";
import { isUndefOrEmpty } from "./StringHelper";
import { OkCancelButtons } from "./OkCancelButtons"

interface Props {
	open: boolean;
	list: ShoppingList;
	onClose: () => void;
}

export function ChangeShoppingListNameDialog({ open, list, onClose }: Props) {
	const classes = useStyles();
	const oldName: string = list.name;
	const [newShoppingListName, setNewShoppingListName] = React.useState(oldName || "");
	const shoppingListActions = useActions(ShoppingListActions);

	const isValid = (): boolean => !isUndefOrEmpty(newShoppingListName);

	const handleClose = () => {
		onClose();
	};

	const handleChange = (event: any) => {
		setNewShoppingListName(event.target.value);
	};

	return (
		<Dialog open={open} onClose={handleClose}>
			<DialogTitle>Einkaufsliste umbenennen</DialogTitle>
			<TextField
				id="multiline-flexible"
				value={newShoppingListName}
				onChange={handleChange}
				className={classes.textField}
				placeholder="Name"
			/>
			<OkCancelButtons 
				onOk={() => { shoppingListActions.renameList(list.id, newShoppingListName); }}
				enableOk={isValid()}
				onClose={handleClose}
			/>
		</Dialog>
	);
}

const useStyles = makeStyles({
	textField: {
		width: "80%",
		margin: 20,
	},
});
