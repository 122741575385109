// prettier-ignore
import * as React from "react";
import { Route, Router,	useLocation } from "react-router-dom";

import {
	AppBar,
	Divider,
	Drawer as DrawerMui,
	IconButton,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Tab,
	Tabs,
	Toolbar,
	Typography,
	useMediaQuery,
} from "@material-ui/core";
import { Theme, makeStyles } from "@material-ui/core/styles";

import HomeIcon from "@material-ui/icons/Home";
import MenuIcon from "@material-ui/icons/Menu";
import ShoppingIcon from "@material-ui/icons/ShoppingCart";
import StoreIcon from "@material-ui/icons/Storefront";
import ListAltIcon from '@material-ui/icons/ListAlt';
import AddIcon from '@material-ui/icons/Add';
import InfoIcon from '@material-ui/icons/Info';

import { useSelector } from "react-redux";
import { RootState } from "./reducers";
import { ShoppingList } from "./model";

import { history } from "./configureStore";
import { HomePage, ShopPage, ItemPage, AboutPage } from "./pages";
import { withRoot } from "./withRoot";
import { AddShoppingListDialog } from "./components"

function Routes() {
	const classes = useStyles();

	return (
		<div className={classes.content}>
			<Route exact={true} path="/" component={HomePage} />
			<Route exact={true} path="/about" component={AboutPage} />
			<Route exact={true} path="/lists/:id" component={ItemPage} />
			<Route exact={true} path="/lists/:id/add" component={ItemPage} />
			<Route exact={true} path="/lists/:id/go" component={ShopPage} />
		</div>
	);
}

function OpenCloseDialogHelper(initialState: boolean) {
	const [open, setOpen] = React.useState(false);
	return {
		open: open,
		setOpen: setOpen,
		handleClose: () => setOpen(false),
		handleOpen: () => setOpen(true)
	}
}

function Drawer(
		{ closeDrawer }: {closeDrawer: () => void}
) {
	const classes = useStyles();
	const shoppingLists = useSelector((state: RootState) => state.shoppingLists);
	const addShoppingListDialogHelper = OpenCloseDialogHelper(false);

	return (
		<div>
			<div className={classes.drawerHeader} />
			<Divider />
			<List>
				<ListItem button onClick={() => {
					closeDrawer();
					history.push("/");
				}}>
					<ListItemIcon>
						<HomeIcon />
					</ListItemIcon>
					<ListItemText primary="Home" />
				</ListItem>
			</List>
			<Divider />
			<List>
			{shoppingLists.map((list: ShoppingList) => (
				<ListItem button onClick={() => {
					closeDrawer();
					history.push("/lists/" + list.id);
				}}>
					<ListItemIcon>
						<ListAltIcon />
					</ListItemIcon>
					<ListItemText primary={list.name} />
				</ListItem>
			))}
			</List>
			<Divider />
			<List>
				<ListItem button onClick={() => {
					addShoppingListDialogHelper.setOpen(true);
				}}>
					<ListItemIcon>
						<AddIcon />
					</ListItemIcon>
					<ListItemText primary="Neue Liste" />
				</ListItem>
			</List>
			<AddShoppingListDialog open={addShoppingListDialogHelper.open} onClose={addShoppingListDialogHelper.handleClose} />
			<Divider />
			<List>
				<ListItem button onClick={() => {
					closeDrawer();
					history.push("/about");
				}}>
					<ListItemIcon>
						<InfoIcon />
					</ListItemIcon>
					<ListItemText primary="About" />
				</ListItem>
			</List>
		</div>
	);
}

const firstMatchingRegex = (regexes: RegExp[], testString: string, defaultValue: number) => {
	let index = regexes.findIndex((regex) => regex.test(testString));

	if (index >= 0) {
		return index;
	} else {
		return defaultValue;
	}
};

const BottomTabBar = () => {
	const classes = useStyles();

	let location = useLocation();
	const { pathname } = location;

	if (!/^\/lists\/([^/])+/.test(pathname)) {
		return (<div/>); 
	}

	const tabValue = firstMatchingRegex([/\/add$/, /\/go$/], pathname, 0);
	let id = '';
	if (pathname != null) {
		let match = pathname.match(/^\/lists\/([^/]+)/);
		if (match != null) {
			id = match[1];
		}
	}

	const selectTab = (key: number) => {
		switch (key) {
			case 0:
				history.push(`/lists/${id}/add`);
				break;
			case 1:
				history.push(`/lists/${id}/go`);
				break;
			default:
				history.push(`/lists/${id}/add`);
				break;
		}
	};

	return (
		<AppBar
			position="fixed"
			color="primary"
			className={classes.appBarBottom}
		>
			<Tabs
				value={tabValue}
				aria-label="simple tabs"
				variant="fullWidth"
				onChange={(event, id) => selectTab(id)}
			>
				<Tab label="Aufschreiben" icon={<ShoppingIcon />}  />
				<Tab label="Einkaufen" icon={<StoreIcon />} />
			</Tabs>
		</AppBar>
	);
}

function App() {
	const classes = useStyles();

	const [drawerOpen, setDrawerOpen] = React.useState(false);
	const handleDrawerToggle = () => {
		setDrawerOpen(!drawerOpen);
	};

	
	const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

	return (
		<Router history={history}>
			<div className={classes.root}>
				<div className={classes.appFrame}>
					<AppBar className={classes.appBarTop} position="fixed">
						<Toolbar>
							<IconButton
								color="inherit"
								aria-label="open drawer"
								onClick={handleDrawerToggle}
							>
								<MenuIcon />
							</IconButton>
							<Typography
								variant="h6"
								color="inherit"
								noWrap
							>
								Hamsta 🐹🧻
							</Typography>
						</Toolbar>
					</AppBar>
					<DrawerMui
						variant="temporary"
						anchor={"left"}
						open={drawerOpen}
						classes={{
							paper: classes.drawerPaper,
						}}
						onClose={handleDrawerToggle}
						ModalProps={{
							keepMounted: true, // Better open performance on mobile.
						}}
					>
						<Drawer
							closeDrawer={() => setDrawerOpen(false)}
						/>
					</DrawerMui>
					<Routes />
					<BottomTabBar/>
				</div>
			</div>
		</Router>
	);
}

const drawerWidth = 300;
const useStyles = makeStyles((theme: Theme) => ({
	root: {
		width: "100%",
		height: "100%",
		zIndex: 1,
		overflow: "hidden",
	},
	appFrame: {
		position: "relative",
		display: "flex",
		width: "100%",
		height: "100%",
	},
	appBarTop: {
		zIndex: theme.zIndex.drawer + 1,
	},
	appBarBottom: {
		top: "auto",
		bottom: 0,
	},
	drawerHeader: { ...theme.mixins.toolbar },
	drawerPaper: {
		width: 250,
		backgroundColor: theme.palette.background.default,
		[theme.breakpoints.up("md")]: {
			width: drawerWidth,
			position: "relative",
			height: "100%",
		},
	},
	content: {
		backgroundColor: theme.palette.background.default,
		width: "100%",
		height: "calc(100% - 56px)",
		marginBottom: 144,
		marginTop: 72,
		[theme.breakpoints.up("sm")]: {
			height: "calc(100% - 64px)",
			marginTop: 64,
		},
	},
}));

export default withRoot(App);
